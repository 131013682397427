export default [{
    header: 'apps',
},
{
    title: 'Users',
    icon: 'UsersIcon',
    children: [
        {
          title: 'User List',
          route: 'users-list'
        },
        // {
        //     title: 'User Verification',
        //     icon: 'UserCheckIcon',
        //     route: 'users-verification',
        // },
    ],
},
{
    title: 'Campaigns',
    icon: "LayersIcon",
    children: [
        {
            title: 'Manage',
            route: 'campaigns-manage',
            icon: 'GridIcon',
        },
        {
            title: 'Categories',
            route: 'campaigns-categories',
            icon: 'TargetIcon',
        },
        {
            title: 'Refunds',
            route: 'campaigns-refunds',
            icon: 'CreditCardIcon',
        },
    ]
},
{
    title: 'Transactions',
    icon: "ListIcon",
    children: [
        {
            title: 'Backings',
            route: 'transactions-backings',
            icon: 'FileTextIcon',
        },
    ]
},
{
    title: "Chat",
    route: "apps-chat",
    icon: "MessageSquareIcon"
}
    // {
    //   title: 'API',
    //   icon: 'CodeIcon',
    // },
]